<template>
  <div id="app">
    <div class="base-timer">
      <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g class="base-timer__circle">
          <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
          <path id="base-timer-path-remaining" :stroke-dasharray="strokeDashArray" class="base-timer__path-remaining" :class="[{green: isGreen },{orange: isOrange },{red: isRed }]"
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            ">
          </path>
        </g>
      </svg>
      <span id="base-timer-label" class="base-timer__label">{{ this.formatTime(this.timeLeft) }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Timer',
  props:{
    timeLimit: {
      required: true,
      default: 30,
      type: Number
    },
    warningThreshold:{
      required: false,
      default: 10,
      type: Number
    },
    alertThreshold:{
      required: false,
      default: 5,
      type: Number
    },
    currentTimerLeft:{
      required: false,
      default: null,
    }
  },
  data(){
    return{
      FULL_DASH_ARRAY : 283,
      timePassed: 0,
      TIME_LIMIT: 0,
      timeLeft: null,
      dataCurrentTimerLeft: null,
      timerInterval: null,
      currentTimer: 0,
      strokeDashArray: 283,
      isGreen: true,
      isOrange: false,
      isRed: false,
      timerRunning: false,
      shutdown: false,
      COLOR_CODES : {
        info: {
          color: "green"
        },
        warning: {
          color: "orange",
          threshold: 10
        },
        alert: {
          color: "red",
          threshold: 5
        }
      },
      remainingPathColor: null,
    }
  },
  mounted(){
    //Set Threshold from props
    this.COLOR_CODES.warning.threshold = this.warningThreshold;
    this.COLOR_CODES.warning.alert = this.alertThreshold;

    //Set left over time from props
    this.dataCurrentTimerLeft = this.currentTimerLeft;

    //Set start timer
    this.computeTimeLeft();

    //Set Colors
    this.remainingPathColor = this.COLOR_CODES.info.color;
    this.TIME_LIMIT = this.timeLimit
  },
  methods:{
    startTimer() {
      if(this.shutDown) return;
      if(this.dataCurrentTimerLeft == null){
        this.resetTimer();
      }else{
        this.computeTimeLeft();
      }

      setTimeout(() => {
        this.timerRunning = true;
        this.countDownRecursion();
      }, 100);
    },
    countDownRecursion(){

      if(this.timerInterval !== null) return;

      this.timerInterval = setInterval(function () {

        if(this.timerRunning && !this.shutDown){
          this.timePassed = this.timePassed += 1;
          this.timeLeft = this.TIME_LIMIT - this.timePassed;
          this.currentTimer = this.formatTime(this.timeLeft);

          this.setCircleDasharray();
          this.setRemainingPathColor(this.timeLeft);

          if (this.timeLeft === 0) {
            this.onTimesUp();
            return;
          }
        }
      }.bind(this), 1000);
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
    setCircleDasharray() {
      const circleDashArray = `${(this.calculateTimeFraction() * 283).toFixed(0)} 283`;
      this.strokeDashArray = circleDashArray
    },
    calculateTimeFraction() {
      const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
      return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
    },
    setRemainingPathColor(timeLeft) {
      const { alert, warning } = this.COLOR_CODES;

      if (timeLeft <= alert.threshold) {
        this.toggleClasses('red');
      } else if (timeLeft <= warning.threshold) {
        this.toggleClasses('orange')
      }
    },
    onTimesUp() {
      this.timerRunning = false;
      this.timePassed = 0;
      this.timeLeft = this.timeLimit
      this.strokeDashArray = this.FULL_DASH_ARRAY;
      this.toggleClasses('green');
      clearInterval(this.timerInterval);
      this.timerInterval = null;
      this.dataCurrentTimerLeft = null;
      this.$emit('timesUp',true);
    },
    toggleClasses(color){
      switch (color) {
        case 'green':
          this.isGreen = true,
          [this.isOrange,this.isRed] = [false,false];
          break;

        case 'orange':
          this.isOrange = true,
          [this.isGreen,this.isRed] = [false,false];
          break;

        case 'red':
           this.isRed = true,
          [this.isGreen,this.isOrange] = [false,false];
          break;

        default:
          break;
      }
    },
    toggleTimer(state){
      this.timerRunning = state;
    },
    resetTimer(){
      this.timerRunning = false;
      this.timePassed = 0;
      this.timeLeft = this.timeLimit;
      this.strokeDashArray = this.FULL_DASH_ARRAY;
      this.toggleClasses('green');
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    },
    computeTimeLeft(){
      if(this.dataCurrentTimerLeft == null){
        this.timeLeft = this.timeLimit;
        this.timePassed = 0;
      }else{
        this.timerRunning = false;
        this.timeLeft = this.dataCurrentTimerLeft;
        this.timePassed = this.TIME_LIMIT -this.timeLeft;
        this.setCircleDasharray();
        this.setRemainingPathColor(this.timeLeft);
        clearInterval(this.timerInterval);
        this.timerInterval = null;

      }
    },
    shutdownTimer(){
      this.shutDown = true;
      this.resetTimer();
    }
  },
  watch:{
    timeLimit:{
      handler: function(data){
        this.timeLeft = data;
        this.TIME_LIMIT = data;
      },
      immediate: true
    },
    timerRunning: {
      handler: function(data){
        this.timerRunning = data;
      },
      immediate: true,
    },
    currentTimerLeft:{
      handler: function(data){
        this.dataCurrentTimerLeft = data;
        this.computeTimeLeft();
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.base-timer {
  position: relative;
  display: flex;
  align-items: center;
}

.base-timer__svg {
  transform: scaleX(-1);
  width: 50px;
  height: 50px;
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #6D393B;
}

.base-timer__path-remaining.orange {
  color: #BB2830;
}

.base-timer__path-remaining.red {
  color: #FA2E39;
}

.base-timer__label {
  font-size: 48px;
  margin-left: 20px;
  width: 100px;

  @media screen and (max-width:1280px){ 
    font-size: 30px;
    margin-left: 10px;
  }
}
</style>
