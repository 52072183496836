<template>
  <div class="table" v-if="tableData">
    <div class="table-options-row" :class="{ 'mobile-contract': isContract}" v-for="details in tableData" :key="details.title">
      <div class="table-title">
        <h3>{{details.title}}</h3>
      </div>
      <template v-if="isNumeric">
        <div class="table-description" v-if="isInput == false" :class="details.textColor" :style="{color: isAlwaysRed ? '#C60000' : ''}">
          <template v-if="details.isMoneyFormat && !details.isPositive || isAlwaysRed">
           ({{details.value | moneyFormat}})
          </template>
          <template v-else-if="details.isMoneyFormat && details.isPositive">
            {{details.value | moneyFormat}}
          </template>
          <template v-else>
            {{details.value}}
          </template>
        </div>
        <div class="input-container" v-else>
          <input type="text" class="table-description" min="0"  v-model="quantity"/>
          <img src="../assets/icons/arrow_up_circle.png" alt="arrow_up" @click="counter(true)">
          <img src="../assets/icons/arrow_down_circle.png" alt="arrow_up" @click="counter(false)">
        </div>
      </template>
      <template v-else>
        <div class="table-description">{{details.value}}</div>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableDetails: {
      required: true
    },
    isInput:{
      required: false,
      default: false
    },
    isNumeric:{
      required: false,
      default: false
    },
    isColorCoded: {
      required: false,
      default: false
    },
    isMoneyFormat: {
      required: false,
      default: false
    },
    isPercentage:{
      required: false,
      default: false
    },
    isAlwaysRed:{
      required: false,
      default: false
    },
    isContract: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return{
      quantity: 0,
      value: '',
      tableData: [],
    }
  },
  created(){
    this.repopulateData()

  },
  methods:{
    counter(type){
      if(type){
        this.quantity++
      }else if(parseInt(this.quantity) > 0){
        this.quantity--;
      }
    },
    repopulateData(){
      this.tableData = [];
      if(this.isNumeric){
        this.tableDetails.forEach(element => {
          if(parseInt(element.value) >= 0){
            this.tableData.push({
              title: element.title,
              value: this.isPercentage ? `${parseFloat(element.value).toFixed(2)}%` : element.value.toString(),
              isMoneyFormat: this.isMoneyFormat,
              textColor: this.isColorCoded ? 'green' : '',
              isPositive: true,
            })
          }else{
            this.tableData.push({
              title: element.title,
              value: this.isPercentage ? `${parseFloat(Math.abs(element.value)).toFixed(2)}%` : `${(Math.abs(element.value).toString())}`,
              isMoneyFormat: this.isMoneyFormat,
              textColor: this.isColorCoded ? 'red' : '',
              isPositive: false,
            })
          }
        });
      }else{
        this.tableData = this.tableDetails;
      }
    }
  },
  watch:{
    quantity(newData){
      this.$emit('valueChange',newData);
    },
    tableDetails:{
      handler: function(){
        this.repopulateData();
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>

*{
  font-family: Arial, Helvetica, sans-serif;
}

.table{
  border: 2px solid #f0f0f0;
  border-radius: 10px;
}

.table-options-row{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  border-left: none;
  overflow: hidden;

  &:first-child{
    border-radius: 8px 8px 0px 0px;
  }
  &:last-child{
    border-radius: 0px 0px 8px 8px;
  }
  .table-title{
    background: var(--gray414241);
    display: grid;
    place-items: center left;
    // border-radius: 5px;
    h3{
      color: white;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 700;
      
      @media screen and (max-width:1280px){
        font-size: 12px;
      }
    }
  }

  .table-description{
    margin-left: 20px;

    &.green{
      color: #009F00;
    }

    &.red{
      color: #C60000;
    }

    @media screen and (max-width:1280px){
      font-size: 12px;
      font-weight: bold;
    }
  }

  .input-container{
    display: flex;
    width: 150px;

    img{
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  input{
    border: none;
    width: 50px;
  }

  & > *{
    padding: 1rem;
  }


}

@include respond(phone) {
  .mobile-contract{
    display: flex !important;
    flex-direction: column;
  }
  .table-options-row{
  border: 1px solid #F0F0F0;
  border-left: none;
  border-radius: 0px;
  overflow: hidden;

  .table-title{
  background: var(--gray414241);
  color: white;
  padding: .5rem;
  }
  .table-description{
    margin-left: 0px;
    padding: .5rem ;
  }

  input{
    border: none;
  }

  & > *{
    padding: 1rem;
  }
}
}

</style>