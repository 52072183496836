import { render, staticRenderFns } from "./TableColumn.vue?vue&type=template&id=6257f047&scoped=true"
import script from "./TableColumn.vue?vue&type=script&lang=js"
export * from "./TableColumn.vue?vue&type=script&lang=js"
import style0 from "./TableColumn.vue?vue&type=style&index=0&id=6257f047&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6257f047",
  null
  
)

export default component.exports