<template>
  <div class="table-col" :style="{'grid-template-columns' : `${columnTemplateCount}`}">
    <div class="table-header" v-for="data in header" :key="data">
      <div class="header-title">
        <h4>{{data}}</h4>
      </div>
    </div>
    <div class="table-body-wrapper" :style="{'grid-column': `1 / ${gridSize + 1}` }">

      <!-- Order Journal -->
      <template v-if="dataType == 'journal'">
        <div class="table-body" 
          v-for="data in propData" :key="data.id" :class="{isForced: data.is_forced}"
          :style="{'grid-template-columns' : `${columnTemplateCount}`}"
          >
          <p>{{data.day}}</p>
          <p :style="{color: data.type === 'BUY' ? '#009F00' : '#C60000'}">{{data.type}}</p>
          <p >{{data.quantity}}</p>
          <p>{{data.price}}</p>
        </div>
      </template>

      <!-- Leaderboard -->
      <template v-else>
        <div class="table-body" 
          v-for="(data,index) in propData" :key="data.id"
          :style="{'grid-template-columns' : `${columnTemplateCount}`}"
          >
          <p>{{index + 1}}</p>
          <p>{{data.user.display_name || `Player ${index + 1}`}}</p> 
          <p >{{data.net_position }}</p>
          <p :style="{color: parseFloat(data.profit) >= 0 ? '#009F00' : '#C60000'}">
            <template v-if="parseFloat(data.profit) >= 0">
              {{Math.abs(data.profit).toString() | moneyFormat}}
            </template>
            <template v-else>
              ({{Math.abs(data.profit).toString() | moneyFormat}})
            </template>
          </p>
          <p>{{data.net_equity | moneyFormat}}</p>
          <p :style="{color: parseFloat(data.margin_excess) >= 0 ? '#009F00' : '#C60000'}">
            <template v-if="parseFloat(data.margin_excess) >= 0">
              {{Math.abs(data.margin_excess).toString() | moneyFormat}}
            </template>
            <template v-else>
              ({{Math.abs(data.margin_excess).toString() | moneyFormat}})
            </template>  
          </p> 
          <p :style="{color: parseFloat(data.equity_margin_ratio) >= 0 ? '#009F00' : '#C60000'}">{{data.equity_margin_ratio }}%</p>
        </div>
      </template>



    </div>
  </div>
</template>

<script>
export default {
  props: ['propData','header','columnTemplateCount','dataType','gridSize'],
  watch:{
    propData:{
      handler: function(newData){
        this.propData = newData;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped> 

  .table-col{
    overflow: auto;
  }

  .table-col, .table-body{
    display: grid;
    grid-template-columns: repeat(4,1fr);

    .table-header{
      display: grid;
      place-items: center;
      text-align: center;
      padding: 0.5rem 0;
      background: var(--gray414241);
      color: white;
      border-left: 2px solid gray;

      @media screen and (max-width:1280px){
        font-size: 12px;
        text-transform: uppercase;
      }
    }

  .isForced{
    background: var(--redC41230);

    p{
      color: white !important;
    }
  }

  .table-body-wrapper{
    grid-column: 1 / 5;
    overflow-y: auto;
  }

  .table-body{
    grid-column: 1 / 5;
    text-align: center;
    border: 1px solid #F0F0F0;
    padding: 0.2rem 0;
    overflow-x: hidden;

    @media screen and (max-width:1280px){
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
</style>